.welcome {
    display: flex;
    text-align: center;
    flex-direction: column;
}
.welcome_group{
    display: flex;
    text-align: center;
    justify-content: center;
    background-color: black;
    color: blanchedalmond;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1em;
}
.alignVertical{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

#ad_create{
    /*background-color: rgb(63, 60, 60);*/
}
.welcome_group .MuiTypography-h4 {
    color: whitesmoke;
}
#ad_create b {
    color: darkorange;
}