.OptionEditView{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5em;
}
.AnswerOptionView {
  display: flex;
  text-align: left;
  /*justify-content: center;*/
  margin-bottom: 1em;
  width: 100%;
}
.AnswerOptionView *{
  text-align: left;
  margin-right: 0.5em;
}
.selectNode{
  /* min-width: 1em; */
  margin-left: 0.5em;
}

.decisionBox{
  display: flex;
  margin-top: 1em;
  text-align: center;
  width: 100%;
}
.OptionDecision{
  margin-left: 0.5em;
  display: flex;
  width: 100%;
}
.item{
  flex-grow: 1;
}
.alignVertical{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.decisionText{
  padding-right: 0.5em;
  border-right: 1px dashed blue;
}
.optionText {
  width: 100%;
}
.optionValue{
  /*display: flex;*/
  width: 100%;
}
.align_right{
  text-align: right;
}