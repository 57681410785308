.Section {
    /* border-left: 5px solid  lightpink;*/
    text-align: left;

    margin-top: 2%;
    margin-bottom: 2%;
    padding-top: 5%;
    padding-bottom: 5%;

}
.information {
    font-style: italic;
    /*padding-left: 2%;*/
}
.sectionTitle {
    background-color: lavender;
}
