h1 {
    width: 100%;
    background-color: white;
}
h2 {
    width: 100%;
    background-color: aliceblue;
}
td,
th {
    text-align: center;
}
td,
th {
    border: 1px solid black;
}

th {
    padding: 1em;
    background-color: gainsboro;
}
td {
    padding-left: 1em;
    padding-right: 1em;
}