.question *{
    /*position: relative;
    left: 30px;*/
    padding-left: 2%;
    overflow-wrap: "break-word",
}
.boxView{
    /* border: 1px solid;*/
    /* display: inline;*/
    /*display: flex;*/
    /*width: 100%;*/
}